<template>
    <Main class="background" :qrcode="true">
        <Header checked="inquiry"></Header>
        <Block class="content">
            <div class="inquiry">
                <div class="button-group">
                    <div class="button" @click="batDel">{{$t('inquiry.bat_delete')}}</div>
                </div>
                <div class="table">
                    <el-table ref="table" v-loading="loading" class="table-border" :data="list" height="100%" header-cell-class-name="table-header" cell-class-name="table-border" border style="width: 100%" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="44" align="center" fixed="left"></el-table-column>
                        <el-table-column align="center" prop="date" :label="$t('inquiry.main_picture')" width="60" fixed="left">
                            <template slot-scope="scope">
                                <div class="center">
                                    <el-image style="width: 48px; height: 36px; display: block;" class="pointer" fit='contain' @click.native="jump('/goodsinfo', {id: scope.row.toy_id})" :src="scope.row.main_picture"
                                    :title="getLanguageData({zh: scope.row.name, en: scope.row.name_en}) + '（' + scope.row.article_number + '）'">
                                        <div slot="error">
                                            <i class="el-icon-picture-outline" style="font-size:36px;"></i>
                                        </div>
                                    </el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :show-overflow-tooltip="false" :prop="getLanguageData({zh: 'name', en: 'name_en'})" :label="$t('inquiry.name')" width="160" fixed="left">
                            <template slot-scope="scope">
                                <div class="pointer ellipsis jump" :title="getLanguageData({zh: scope.row.name, en: scope.row.name_en})" @click="jump('/goodsinfo', {id: scope.row.toy_id})">
                                    {{getLanguageData({zh: scope.row.name, en: scope.row.name_en})}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :show-overflow-tooltip="false" prop="article_number" :label="$t('inquiry.article_number')" width="120">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="scope.row.article_number">
                                    {{scope.row.article_number}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :show-overflow-tooltip="false" :prop="getLanguageData({zh: 'company_name', en: 'company_en_name'})" :label="$t('inquiry.company_name')" width="120">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="getLanguageData({zh: scope.row.company_name, en: scope.row.company_en_name})">
                                    {{getLanguageData({zh: scope.row.company_name, en: scope.row.company_en_name})}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :show-overflow-tooltip="false" prop="mobile" :label="$t('inquiry.mobile')" width="120">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="scope.row.mobile">
                                    {{scope.row.mobile}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :show-overflow-tooltip="false" prop="inquiry_count" :label="$t('inquiry.inquiry_count')" width="90">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="scope.row.inquiry_count">
                                    {{scope.row.inquiry_count}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :show-overflow-tooltip="false" prop="inquiry_time" :label="$t('inquiry.inquiry_time')" width="160">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="scope.row.inquiry_time">
                                    {{scope.row.inquiry_time}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :show-overflow-tooltip="false" prop="inquiry_content" :label="$t('inquiry.inquiry_content')" width="148">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="scope.row.inquiry_content">
                                    {{scope.row.inquiry_content}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" fixed="right" :label="$t('inquiry.operate')" width="180">
                            <template slot-scope="scope">
                                <div class="operate-button-group">
                                    <div class="operate-button operate-button-1" @click="edit(scope.row)">{{$t('inquiry.operate_1')}}</div>
                                    <div class="operate-button operate-button-2" @click="del([scope.row.id])">{{$t('inquiry.operate_2')}}</div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <Page :page.sync="page" :pageSize="pageSize" :total="total" @current-change="handlePage"></Page>
                </div>
            </div>
        </Block>
        <inquiry-dialog v-model="dialogVisible" :item="item" @refurbish="getList"></inquiry-dialog>
    </Main>
</template>

<script>
import Main from '@/components/layout2/layout2-main.vue';
import Header from '@/components/layout2/layout2-header.vue';
import Block from '@/components/layout2/layout2-block.vue';
import Page from '@/components/layout2/layout2-page.vue';
import InquiryDialog from './components/inquiry-dialog';
import { getLanguageData, isEmpty, jump } from "@/libs/tools.js";
import { getLanguage } from "@/lang/index.js";
import { list, del } from "@/api/inquiry.js";

export default {
    name: 'inquiry',
    components: {
        Main,
        Header,
        Block,
        Page,
        InquiryDialog,
    },
    data() {
        return {
            lang: getLanguage(),
            list: [],
            page: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            multipleSelection: [],
            dialogVisible: false,
            item: {},
        }
    },
    computed: {

    },
    mounted() {
        document.title = this.$t("common.title8");
        this.getList();
    },
    methods: {
        getLanguageData,
        jump,
        getList() {
            this.loading = true;
            let data = { page: this.page };
            list(data).then(res => {
                if (res.code == 0) {
                    this.list = res.data.data;
                    this.pageSize = res.data.per_page;
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.msg);
                }
            }).finally(() => {
                this.loading = false;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })

        },
        handlePage(page) {
            this.page = page;
            this.getList();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val.map(item => item.id);
        },
        edit(item) {
            this.item = item;
            this.dialogVisible = true;
        },
        batDel() {
            if (!(this.multipleSelection.length > 0)) {
                this.$message.error(this.$t('common.leastSelectOne'))
                return false;
            }
            this.del(this.multipleSelection);
        },
        del(ids) {
            this.$confirm(this.$t('inquiry.delete_cue'), this.$t('common.cue'), {
                confirmButtonText: this.$t('common.confirm_text'),
                cancelButtonText: this.$t('common.cancel_text'),
                customClass: 'operate-login-cue',
                center: true
            }).then(() => {
                del({ id: ids }).then(res => {
                    if (res.code == 0) {
                        this.getList();
                        this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }).catch(() => {
                // reject(new Error('cancel'));
            });
        }
    },
}
</script>
<style lang="less" scoped>
.background {
    background: #f6f6f6;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.pointer {
    cursor: pointer;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.jump {
    &:hover {
        color: #3053A3;
    }
}
.content {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.inquiry {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .button-group {
        display: flex;
        margin-bottom: 24px;
        height: 32px;
        .button {
            font-size: 12px;
            min-width: 88px;
            height: 32px;
            background: #3053A3;
            color: #ffffff;

            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    .table {
        flex: 1;
        /deep/ .table-header {
            background: #f2f4f6;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            height: 48px;
            border-color: #e8e8e8;
            .cell {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
        /deep/ .table-border {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            padding: 0;
            height: 48px;
            border-color: #e8e8e8;
        }
        /deep/ .operate-button-group {
            display: flex;
            justify-content: center;
            .operate-button {
                font-size: 12px;
                font-weight: 400;
                min-width: 68px;
                height: 24px;
                padding: 0;
                border-radius: 2px;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .operate-button-1 {
                color: #3053A3;
                border: 1px solid #3053A3;
            }
            .operate-button-2 {
                margin-left: 4px;
                color: #666666;
                border: 1px solid #CCCCCC;
            }
        }
        /deep/ .el-table--border {
            .el-table__cell:first-child {
                .cell {
                    padding: 0;
                }
            }
        }
    }
    .page {
        margin-top: 10px;
        height: 32px;
    }
}
</style>
