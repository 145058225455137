<template>
    <el-dialog class="inquiry-dialog" :title="$t('inquiry_dialog.title')" :show-close="false" :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true" top="360px" :visible.sync="dialogVisible" width="600px">
        <el-form ref="form" :validate-on-rule-change="false" :rules="rules" v-loading="loading" :model="info" :show-message="false" label-position="left" size="small">
            <div class="inquiry-item">
                <div class="top">
                    <el-image class="img" fit="contain" :src="info.main_picture">
                    </el-image>
                    <div class="toy-item">
                        <div class="name ellipsis" :title="getLanguageData({zh: info.name, en: info.name_en})">{{getLanguageData({zh: info.name, en: info.name_en})}}</div>
                        <div class="company_name ellipsis" :title="getLanguageData({zh: info.company_name, en: info.company_en_name})">{{getLanguageData({zh: info.company_name, en: info.company_en_name})}}</div>
                        <div class="article_number ellipsis">{{$t("inquiry_dialog.article_number_label")}}{{info.article_number}}</div>
                    </div>

                    <el-form-item prop="inquiry_count">
                        <div class="inquiry_count">
                            <div class="inquiry_count_label">{{$t("inquiry_dialog.inquiry_count_lable")}}</div>
                            <!-- <el-input v-model="info.inquiry_count" class="input"></el-input> -->
                            <el-input-number class="input" :min="0" :step="1" v-model="info.inquiry_count" :controls="false"></el-input-number>
                        </div>
                    </el-form-item>
                </div>

                <el-form-item>
                    <div class="remark">
                        <div class="remark-label">{{$t("inquiry_dialog.remark_label")}}</div>
                        <el-input type="textarea" maxlength="100" show-word-limit v-model="info.inquiry_content" class="input"></el-input>
                    </div>
                </el-form-item>

                <div class="operate-button-group">
                    <div class="operate-button operate-button-1" @click="dialogVisible=false">{{$t('inquiry_dialog.button1')}}</div>
                    <div class="operate-button operate-button-2" @click="save">{{$t('inquiry_dialog.button2')}}</div>
                </div>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import { getLanguageData, isEmpty } from "@/libs/tools.js";
import { getLanguage } from "@/lang/index.js";
import { update } from "@/api/inquiry.js";

export default {
    name: 'inquiry-dialog',
    components: {

    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            lang: getLanguage(),
            info: {},
            loading: false,
            rules: {
                // inquiry_count: [
                //     { required: true, message: this.$t('inquiry_dialog.fieldText1Required'), trigger: 'blur' },
                // ],
            },
        }
    },
    mounted() {

    },
    methods: {
        getLanguageData,
        save() {
            this.$refs.form.validate((valid, err) => {
                if (valid) {
                    let data = {
                        id: this.info.id,
                        inquiry_count: this.info.inquiry_count,
                        inquiry_content: this.info.inquiry_content,
                    };
                    update(data).then(res => {
                        if (res.code == 0) {
                            this.$message.success(res.msg);
                            this.$emit('refurbish');
                            this.dialogVisible = false;
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    for (let key in err) {
                        this.$message.error(err[key][0]['message']);
                        return false;
                    }
                }
            });
        },
    },
    computed: {
        dialogVisible: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        },
    },
    watch: {
        dialogVisible: {
            immediate: true,
            handler: function (val) {
                if (val == true) {
                    this.info = { ...this.item };
                }
            }
        },
    }
}
</script>
<style lang="less" scoped>
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.inquiry-dialog {
    /deep/ .el-dialog {
        border-radius: 10px;
    }
    /deep/ .el-dialog__header {
        height: 49px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        .el-dialog__title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }
    }
    /deep/ .el-dialog__body {
        padding: 0;
    }
    .inquiry-item {
        display: flex;
        flex-direction: column;
        margin: 0 24px;
        .top {
            height: 75px;
            margin-top: 16px;
            display: flex;
            .img {
                width: 100px;
                height: 75px;
                margin-right: 10px;
            }
            .toy-item {
                flex: 1;
                overflow: hidden;
                .name {
                    font-size: 16px;
                    font-weight: bold;
                    color: #333333;
                }
                .company_name {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 8px;
                }
                .article_number {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 8px;
                }
            }
            .inquiry_count {
                width: 170px;
                height: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                .inquiry_count_label {
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
        .remark {
            height: 80px;
            margin-top: 16px;
            display: flex;
            .remark-label {
                white-space: nowrap;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                color: #666666;
            }
        }
        .operate-button-group {
            margin-top: 16px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .operate-button {
                font-size: 14px;
                font-weight: 400;
                min-width: 100px;
                height: 32px;
                padding: 0;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .operate-button-1 {
                color: #999999;
                border: 1px solid #999999;
            }
            .operate-button-2 {
                margin-left: 16px;
                color: #ffffff;
                border: 1px solid #3053A3;
                background-color: #3053A3;
            }
        }
    }
    .input {
        /deep/ .el-input__inner {
            height: 36px;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 0px;
        }
        /deep/ .el-textarea__inner {
            height: 80px;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 0px;
        }
    }
    /deep/ .el-input-number .el-input__inner{
        text-align: left;
    }
}
</style>
